import React from "react";
import styled from "styled-components";
import DocumentationSection from "../../components/docs/DocumentationSection";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { RouterLocation } from "../../types/types";

interface Props {
  location: RouterLocation;
}

function VersioningPage({ location }: Props) {
  const title = "Versioning";
  const description = "Learn about versioning for the Merge API.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />

      <DocumentationSection title="Overview">
        <p>
          Merge's APIs are currently at version v1, with no major releases planned. We've
          intentionally designed our APIs to be robust and stable, ensuring that all updates avoid
          breaking changes that usually require developers to spend time upgrading. We commit to
          providing a notice before any release of a major version, breaking change, or deprecation
          in our API.
        </p>
      </DocumentationSection>

      <CrossGuideLinks location={location} style={{ marginTop: "12px " }} />
    </StaticPageContainer>
  );
}

export default VersioningPage;
